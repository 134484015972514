import { addons } from '@storybook/manager-api';
import { create, themes } from '@storybook/theming';
import brandImageLight from './storybook-logo-light.svg';
import brandImageDark from './storybook-logo-dark.svg';

const isDarkMode = localStorage.getItem('gds-color-mode') === 'dark';
const currentTheme = isDarkMode ? 'dark' : 'light';

const theme = create({
    ...themes[currentTheme],
    brandTitle: 'Gling Design System',
    brandImage: isDarkMode ? brandImageDark : brandImageLight,
    colorPrimary: '#00BCD4',
    colorSecondary: '#005F64',
    fontBase: '"Spoqa Han Sans Neo", sans-serif',
    appBg: isDarkMode ? '#212529' : '#F8F9FA',
    appContentBg: isDarkMode ? '#121417' : '#FFFFFF',
});

addons.setConfig({ theme: theme });
